import useStore from 'Hooks/useStore';
import Header from 'Components/AppWrapper/Header/Header.component';
import SideMenu from 'Components/AppWrapper/SideMenu/SideMenu.component';
import { sideMenuRequestWithNewFeatureFlag } from 'Services/PrefetchRequests';
import useUi from 'Hooks/useUi';
import useSideContent from 'Hooks/useSideContent';
import Footer from 'Components/AppWrapper/Footer/Footer.component';
import SideContent from 'Components/AppWrapper/SideContent/SideContent.component';
import WelcomeModal from 'Components/Home/WelcomeModal/WelcomeModal.component';
import AppModal from 'Components/AppWrapper/AppModal/AppModal.component';
import Breadcrumb from 'Components/AppWrapper/Breadcrumb/Breadcrumb.component';
import AppDrawer from 'Components/AppWrapper/AppDrawer/AppDrawer.component';
import GTM from 'Components/AppWrapper/GTM/Gtm.component';
import UpdateAddress from 'Components/Home/UpdateAddress/UpdateAddress.component';
import { getFreightRequest } from 'Services/UpdateFreightRequest';
import MaintenanceStatusModal from 'Components/Home/MaintenanceStatusModal/MaintenanceStatusModal.component';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';

const App: React.FC = () => {
  const [isOpenModalUpdatefreight, setisOpenModalUpdatefreight] = useState<boolean>(false);
  const { store, storeList } = useStore();
  const { sideMenuIsOpen, toggleSideMenuIsOpen } = useUi();
  const { sideContentIsOpen, toggleSideContentIsOpen } = useSideContent();
  const { data } = useQuery(['sideMenu'], sideMenuRequestWithNewFeatureFlag, {
    enabled: false
  });

  useQuery(['getFreight'], getFreightRequest, {
    onSuccess: (response) => {
      setisOpenModalUpdatefreight(!response);
    }
  });

  useEffect(() => {
    if (navigator.userAgent.includes('Firefox')) {
      document.body.classList.add('with-layout');
      return () => {
        document.body.classList.remove('with-layout');
      };
    }
  }, []);

  return (
    <>
      <Header
        sideMenuIsOpen={sideMenuIsOpen}
        toggleSideMenuIsOpen={toggleSideMenuIsOpen}
        actualStore={store}
        shouldAllowChangeStore={storeList.length > 1}
      />
      <SideMenu items={data} isOpen={sideMenuIsOpen} toggleIsOpen={toggleSideMenuIsOpen} />
      <WelcomeModal storeName={store.pastaLoja} />
      <MaintenanceStatusModal
        content="Em 02/04, às 01h, sua Loja VirtUOL ficará inacessível por 15 min para reforçar a segurança do serviço."
        storeName={store.pastaLoja}
        activePeriod={{
          from: new Date('2025-04-01T06:00:00'),
          until: new Date('2025-04-02T01:30:00')
        }}
      />
      {isOpenModalUpdatefreight && <UpdateAddress />}
      <SideContent
        name="Relatório"
        sideContentIsOpen={sideContentIsOpen}
        toggleSideContentIsOpen={toggleSideContentIsOpen}
      />
      <AppModal />
      <AppDrawer />
      <main id="app">
        <Suspense>
          <Breadcrumb />
          <Outlet />
        </Suspense>
      </main>
      <Footer />
      <GTM />
    </>
  );
};

export default App;
