import lazyLoad from './LazyLoad';
import { IRoutes } from '../Types/IRoutes';
import App from 'App';
import Probe from 'Pages/Probe/Probe.page';
import { Navigate } from 'react-router-dom';

const storeSelectRoute: IRoutes = {
  name: 'storeSelect',
  path: '/stores',
  pageData: lazyLoad('StoreSelect/StoreSelect')
};

const openRoutes: IRoutes[] = [
  {
    name: 'probe',
    path: '/probe',
    pageData: {
      component: Probe
    }
  },
  {
    name: 'error',
    path: '/error',
    pageData: lazyLoad('Error/Error')
  },
  {
    name: 'notFound',
    path: '/*',
    pageData: {
      component: () => <Navigate to="/error?code=404" />
    }
  }
];

const routes: IRoutes[] = [
  {
    name: 'App',
    path: '/',
    pageData: {
      component: App
    },
    nestedRoutes: [
      {
        name: 'home',
        path: '/',
        pageData: lazyLoad('Home/Home')
      },
      {
        name: 'products',
        path: '/produtos',
        pageData: lazyLoad('Products/Products')
      },
      // {
      //   name: 'WYSIWYG',
      //   path: '/wysiwyg',
      //   pageData: lazyLoad('Wysiwyg/Wysiwyg')
      // },
      {
        name: 'product',
        path: '/produtos/cadastrar',
        pageData: lazyLoad('Product/Product')
      },
      {
        name: 'product-edit',
        path: '/produtos/:id/editar',
        pageData: lazyLoad('Product/Product')
      },
      {
        name: 'aplicativos',
        path: '/aplicativos',
        pageData: lazyLoad('Apps/Apps')
      },
      {
        name: 'aplicativos-detalhes',
        path: '/aplicativos/:appParam',
        pageData: lazyLoad('Apps/AppDetails/AppDetails')
      },
      {
        name: 'gestaofrete',
        path: '/configuracoes/frete',
        pageData: lazyLoad('ShippingManagement/ShippingManagement')
      },
      {
        name: 'productshowcase',
        path: '/produtos/vitrines',
        pageData: lazyLoad('ProductShowcase/ProductShowcase')
      },
      {
        name: 'showcase-new',
        path: '/produtos/vitrines/novo',
        pageData: lazyLoad('ShowCase/ShowCase')
      },
      {
        name: 'showcase-edit',
        path: '/produtos/vitrines/:id/editar',
        pageData: lazyLoad('ShowCase/ShowCase')
      },
      {
        name: 'adm-users-list',
        path: '/configuracoes/usuarios-adicionais',
        pageData: lazyLoad('AdmUsers/AdmUsers')
      },
      {
        name: 'adm-user-new',
        path: '/configuracoes/usuarios-adicionais/novo',
        pageData: lazyLoad('AdmUser/AdmUser')
      },
      {
        name: 'adm-user-edit',
        path: '/configuracoes/usuarios-adicionais/:id/editar',
        pageData: lazyLoad('AdmUser/AdmUser')
      },
      {
        name: 'domains',
        path: '/dominios/*',
        pageData: lazyLoad('Dominios/Dominios')
      },
      {
        name: 'themes',
        path: '/layout/listar',
        pageData: lazyLoad('Themes/Themes')
      },
      {
        name: 'order',
        path: '/pedidos/:id',
        pageData: lazyLoad('OrderDetail/OrderDetail')
      }
    ]
  },
  {
    name: 'redirect',
    path: '/redirect',
    pageData: lazyLoad('Redirect/Redirect')
  }
];

export default { openRoutes, routes, storeSelectRoute };
