import S from './Breadcrumb.component.style.module.scss';
import useUi from 'Hooks/useUi';
import GTMEvent from 'Utils/GTM/event';
import { Link } from 'react-router-dom';
import { Breadcrumb as UOLBreadcrumb } from 'uol-host-react-ui';

const Breadcrumb: React.FC = () => {
  const { breadcrumbContent } = useUi();

  return breadcrumbContent.length ? (
    <UOLBreadcrumb className={S.container}>
      {breadcrumbContent.map((item, index) =>
        item.path ? (
          <GTMEvent e_label="Breadcrumb" e_action={`Link-${item.name}`} key={index}>
            <Link to={item.path}>{item.name}</Link>
          </GTMEvent>
        ) : (
          <span key={index}>{item.name}</span>
        )
      )}
    </UOLBreadcrumb>
  ) : (
    <></>
  );
};

export default Breadcrumb;
