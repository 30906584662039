import isGatewayAuthError from 'Utils/IsGatewayAuthError';
import ForceLogin from 'Utils/RedirectLogin';
import axios, { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { apm } from '@elastic/apm-rum';

export default class ClientBuilder {
  private readonly instance: AxiosInstance;

  constructor() {
    this.instance = axios.create();
  }

  withBaseURL(baseURL: string) {
    this.instance.defaults.baseURL = baseURL;
    return this;
  }

  withDefaults() {
    this.instance.defaults.withCredentials = true;

    this.instance.interceptors.request.use((config) => {
      const newConfig = config;
      if (newConfig.headers) {
        newConfig.headers['Correlation-ID'] = uuidv4();
        newConfig.headers['Impersonate-Token'] = uuidv4();
      }

      const transaction = apm.getCurrentTransaction();
      if (transaction) {
        transaction.addLabels({
          request_hash: newConfig.data?.request,
          request_body: newConfig.data?.json
        });
        const debugId = localStorage.getItem('debugId');
        if (debugId) {
          transaction.addLabels({ debug_id: debugId });
        }
      }
      return newConfig;
    });

    this.instance.interceptors.response.use(
      (response) => {
        const transaction = apm.getCurrentTransaction();
        if (transaction) {
          try {
            transaction.addLabels({
              success_request_hash: response.config.data?.request,
              success_request_body: response.config.data?.json,
              success_response_status: response.status,
              success_response_data: JSON.stringify(response.data)
            });
            const debugId = localStorage.getItem('debugId');
            if (debugId) {
              transaction.addLabels({ debug_id: debugId });
            }
          } catch (e) {
            console.error('Erro ao adicionar labels APM (sucesso):', e);
          }
        }
        return response;
      },
      (error) => {
        if (error.isAxiosError) {
          const transaction = apm.getCurrentTransaction();
          if (transaction) {
            try {
              transaction.addLabels({
                error_request_hash: error.config?.data?.request,
                error_request_body: error.config?.data?.json,
                error_response_status: (error.response?.status ?? 0).toString(),
                error_response_data: JSON.stringify(error.response?.data)
              });
              const debugId = localStorage.getItem('debugId');
              if (debugId) {
                transaction.addLabels({ debug_id: debugId });
              }
            } catch (err) {
              console.error('Erro ao adicionar labels APM:', err);
            }
          }
        }
        if (isGatewayAuthError(error)) {
          ForceLogin();
        }
        return Promise.reject(error);
      }
    );
    return this;
  }

  build() {
    return this.instance;
  }
}
