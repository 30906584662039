import { init as initApm } from '@elastic/apm-rum';

export const apm = initApm({
  serviceName: 'lojavirtuol-admin-client',
  environment: 'uh-store',
  serverUrl: 'https://run.service.uol.com.br',
  instrument: true,
  logLevel: 'info'
});

export { initApm };
