import pageRoutes from './Routes';
import { SuspenseRouteWrapper } from './LazyLoad';
import { initApm } from '../Services/ElasticApm';
import Tracking from 'Utils/Tracking/view';
import GenerateRoute from 'Utils/GenerateRoute';
import ContextProvider from 'App/Global/Context/ContextProvider';
import RequireStore from 'Utils/RequireStore';
import RequireAuth from 'Utils/RequireAuth';
import ErrorBoundary from 'App/ErrorBoundary';
import ValidateRoute from 'Utils/ValidateRoute';
import ScrollToTop from 'Components/General/ScrollToTop/ScrollToTop.component';
import { BrowserRouter, Route } from 'react-router-dom';
import React from 'react';
import { ApmRoutes } from '@elastic/apm-rum-react';

const Router: React.FC = () => {
  const { openRoutes, routes, storeSelectRoute } = pageRoutes;
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ApmRoutes apm={initApm}>
        <Route element={<ErrorBoundary />}>
          <Route element={<Tracking />}>
            <Route element={<RequireAuth />}>
              <Route element={<ContextProvider />}>
                <Route element={<RequireStore />}>
                  <Route element={<ValidateRoute />}>
                    <Route element={<SuspenseRouteWrapper />}>
                      {routes.map((pageRoute) => GenerateRoute(pageRoute))}
                    </Route>
                  </Route>
                </Route>
                {GenerateRoute(storeSelectRoute)}
              </Route>
            </Route>
            <Route element={<SuspenseRouteWrapper />}>
              {openRoutes.map((pageRoute) => GenerateRoute(pageRoute))}
            </Route>
          </Route>
        </Route>
      </ApmRoutes>
    </BrowserRouter>
  );
};

export default Router;
