import S from './MaintenanceStatusModal.component.style.module.scss';
import logoUol from '../../../Assets/Images/PNG/logo-virtuol.png';
import ilustra from '../../../Assets/Images/SVG/manutencaoIlustra.svg';
import isDateBetween from 'Utils/isDateBetween';
import { Modal } from 'uol-host-react-ui';
import { useState } from 'react';

type Props = {
  storeName: string;
  content: React.ReactNode;
  activePeriod: {
    from: Date;
    until: Date;
  };
};

const now = new Date();

const MaintenanceStatusModal: React.FC<Props> = ({ storeName, activePeriod, content }) => {
  const localStorageKey = `maintence-popup-${activePeriod.from.toString()}-${activePeriod.until.toString()}`;

  const [isOpen, setIsOpen] = useState<boolean>(
    !localStorage.getItem(localStorageKey) &&
      isDateBetween(now, activePeriod.from, activePeriod.until)
  );
  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem(localStorageKey, 'true');
  };

  return (
    <Modal onClose={handleClose} show={isOpen}>
      <header className={S.headerStyle}>
        <img src={logoUol} alt="logoUol" />
        <p></p>
      </header>
      <aside className={S.welcome}>
        <img src={ilustra} alt="" />
        <div className={S.welcomeContent}>
          <p>Manutenção preventiva</p>
        </div>
      </aside>
      <article className={S.contentLoja}>
        <p>
          Olá {storeName}.
          <br />
          <br />
          {content}
        </p>
        <span></span>
      </article>
      <footer>
        <p>Equipe Loja VirtUOL</p>
      </footer>
    </Modal>
  );
};

export default MaintenanceStatusModal;
