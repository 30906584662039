import HTTP from 'Types/HTTP';
import LogError from 'Utils/LogError';
import AxiosRequest from 'Utils/AxiosRequest';
import MSResult from 'Types/HTTP/MSResult';
import { AxiosRequestConfig } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';

export type UseQueryCallback<T> = (useQueryParams: QueryFunctionContext) => Promise<T>;

type Options<T, K> = AxiosRequestConfig & {
  body?: unknown;
  responseDataTransform?: (data: T) => K;
  redisCacheCallback?: (data: T) => string;
  rawResponse?: boolean;
};

const MSRequest = <T, K = T>(
  method: HTTP,
  url: string,
  options: Options<T, K> = {}
): UseQueryCallback<K> => {
  return async ({ signal }) => {
    let response;
    switch (method) {
      case HTTP.GET:
        response = await AxiosRequest.get<MSResult<T>>(url, { ...options, signal });
        break;
      case HTTP.POST:
        if (options.body) {
          response = await AxiosRequest.post<MSResult<T>>(url, options.body, {
            ...options,
            signal
          });
          break;
        }
        response = await AxiosRequest.post<MSResult<T>>(url, { ...options, signal });
        break;
      case HTTP.PUT:
        if (options.body) {
          response = await AxiosRequest.put<MSResult<T>>(url, options.body, {
            ...options,
            signal
          });
          break;
        }
        response = await AxiosRequest.put<MSResult<T>>(url, { ...options, signal });
        break;
      case HTTP.DELETE:
        response = await AxiosRequest.delete<MSResult<T>>(url, { ...options, signal });
        break;
      default:
        const safeGuard: never = method;
        throw new Error('Unknown HTTP method' + safeGuard);
    }
    const data: T = options.rawResponse
      ? (response.data as T)
      : response.data.result ?? (response.data as T);

    let responseData: K = data as unknown as K;

    if (options.responseDataTransform) {
      responseData = options.responseDataTransform(data);
    }

    if (options.redisCacheCallback) {
      const redisKey = options.redisCacheCallback(data);
      AxiosRequest.delete('/company/cache/limpar/' + redisKey).catch(LogError);
    }
    return responseData;
  };
};

export const MSRequestMutation = <T, K = T>(
  method: HTTP,
  url: string,
  options: Options<T, K> = {}
): Promise<K> => {
  const abortController = new AbortController();

  const ReactQueryProps = { signal: abortController.signal } as QueryFunctionContext;

  return MSRequest<T, K>(method, url, { ...options })(ReactQueryProps);
};

export default MSRequest;
