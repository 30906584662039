import { ISideMenu, hasChild } from 'Types/ISideMenu';
import useStore from 'Hooks/useStore';
import { useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

const IGNORE_LIST = [
  '/dominios',
  '/layout/listar' //TODO: Remover depois de publicação em prod
];

const IGNORE_REDIRECT = [
  '?to=/dashBoard.asp',
  '?to=/logoff.asp',
  '?to=/querystringvalidator_ajax.asp',
  '?to=/frete/correios.asp',
  '?to=/frete/personalizada.asp',
  '?to=/frete/frenet.asp',
  '?to=%2Flojinha%2Fdetalhe.asp%3FIdLojaAplicativos%3D10',
  '?to=%2Flojinha%2Fdetalhe.asp%3FIdLojaAplicativos%3D17',
  '?to=%2Flojinha%2Fdetalhe.asp%3FIdLojaAplicativos%3D83'
];

const BLOCK_ACCESS = [/\/produtos\/cadastrar/, /\produtos\/[0-9]+\/editar/];

/*
|=======================================
| Helper
|=======================================
*/
const parseMenu = (sideMenuCache: ISideMenu[]) => {
  return (
    sideMenuCache
      .map((item) => {
        if (hasChild(item)) {
          return item.childMenuItems.map((child) => child.link);
        } else {
          return item.to;
        }
      })
      .flat()
      .filter((path) => !['/', ...IGNORE_LIST].includes(path)) || []
  );
};

/*
|=======================================
| Route Validation
|=======================================
*/
const ValidateRoute = () => {
  const queryClient = useQueryClient();
  const { pathname, search } = useLocation();
  const { isSuperAdm } = useStore();
  const navigate = useNavigate();

  const sideMenuCache = queryClient.getQueryData<ISideMenu[]>(['sideMenu']);

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'production' && !isSuperAdm) {
      if (pathname === '/' && !sideMenuCache?.some((item) => item.nome === 'Inicio')) {
        navigate('/', { state: { notAllowed: true } });
      }

      if (BLOCK_ACCESS.some((item) => item.test(pathname))) {
        navigate('/', { state: { notAllowed: true } });
      }

      if (
        sideMenuCache?.length &&
        pathname !== '/' &&
        !IGNORE_REDIRECT.includes(search) &&
        !IGNORE_LIST.some((path) => pathname.startsWith(path))
      ) {
        const menuPaths = parseMenu(sideMenuCache);
        const newPath = pathname === '/redirect' && search ? search.replace('?to=', '') : pathname;

        if (
          !menuPaths.some((path) =>
            newPath.startsWith(
              path.endsWith('.asp') ? path.replace(path.replace(/^.*[\\/]/, ''), '') : path
            )
          )
        ) {
          navigate('/', { state: { notAllowed: true } });
        }
      }
    }
  }, [sideMenuCache, navigate, pathname, search, isSuperAdm]);

  return <Outlet />;
};

export default ValidateRoute;
